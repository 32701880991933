export enum CONST {
  IsDeleted = 'IsDeleted',
  ParentFolderId = 'ParentFolderId',
  FolderId = 'FolderId',
  FolderPath = 'FolderPath',
  DocumentId = 'DocumentId',
  CompanyId = 'CompanyId',
  ParentId = 'ParentId',
  LocationId = 'LocationId',
  MainLocationId = 'MainLocationId',
  TrainerId = 'TrainerId',
  DateTimeId = 'DateTimeId',
  AttendeeId = 'AttendeeId',
  getOrganisation = 'organization/getOrganization',
  emptyGuid = '00000000-0000-0000-0000-000000000000',
  BookingDate = 'BookingDate',
  CreatedDate = 'CreatedDate',
  BookableAsSeries = 'BookableAsSeries',
  CourseId = 'CourseId',
  RoomId = 'RoomId',
  EmployeeId = 'EmployeeId',
  TypeDto = 'TypeDto',
  Type = 'Type',
  AppointmentId = 'AppointmentId',
  Date = 'Date',
  DateEnter = 'DateEnter',
  StatusDto = 'StatusDto',
  Status = 'Status',
  UserId = 'UserId',
  AppointmentDate = 'AppointmentDate',
  TimeFrom = 'TimeFrom',
  TimeFromString = 'TimeFromString',
  DepartmentIds = 'DepartmentIds',
  DepartmentId = 'DepartmentId',
  NewsId = 'NewsId',
  CourseType = 'CourseType',
  End = 'End',
  AttendeeAppUserId = 'AttendeeAppUserId',
  PeriodStartDate = 'PeriodStartDate',
  PeriodEndDate = 'PeriodEndDate',
  InvoiceId = 'InvoiceId',
  PaymentStatus = 'PaymentStatus',
  InvoicePaymentStatus = 'InvoicePaymentStatus',
  CourseActive = 'CourseActive',
  CanceledDate = 'CanceledDate',
  Active = 'Active',
  Role = 'Role',
  TillDate = 'TillDate',
  Id = 'Id',
  ApplicationUserId = 'ApplicationUserId',
  UserPermissions = 'UserPermissions',
  IsOnline = 'IsOnline',
  MembershipTypeId = 'MembershipTypeId',
  ValidFrom = 'ValidFrom',
  ValidTill = 'ValidTill',
  IsCancelled = 'IsCancelled',
  AttendeeIds = 'AttendeeIds',
  AuthoritiesString = 'AuthoritiesString',
  VisibleForUser = 'VisibleForUser',
  ReferenceId = 'ReferenceId',
  CostId = 'CostId',
}

export enum TABLE_NAMES {
  Trainer = 'TrainerDm',
  Course = 'CourseDm',
  DateTime = 'DateTimeDm',
}

export enum ErrorCodes {
  QrCodeInvalid = 'QrCodeInvalid',
}

// for `BackendDocument` search via `getDocuments` + OData
export const documentChildEntities = [
  'Notes',
  'Note',
  'AddressLastName',
  'AddressFirstName',
];

export const odataStrings: any = {
  Notes: 'DocumentDetailComments/any(a:contains(tolower(a/Message)',
  Note: 'DocumentDetailComments/any(a:contains(tolower(a/Message)',
  AddressLastName: '(contains(tolower(Address/Nachname)',
  AddressFirstName: '(contains(tolower(Address/Vorname)',
};

export const maxFileSize5MB = 5242880;
export const maxFileSize2MB = 2097152;

export const TableConstants: {
  readonly maxTooltipItemLength: number;
  readonly maxTooltipItemLengthMobile: number;
  readonly defaultItemsPerPageOptions: {
    readonly value: number;
    readonly title: string;
  }[];
} = {
  maxTooltipItemLength: 64,
  maxTooltipItemLengthMobile: 20,
  defaultItemsPerPageOptions: [
    { value: 5, title: '$vuetify.pagination.pageSizes.5' },
    { value: 10, title: '$vuetify.pagination.pageSizes.10' },
    { value: 15, title: '$vuetify.pagination.pageSizes.15' },
    { value: 20, title: '$vuetify.pagination.pageSizes.20' },
    { value: 25, title: '$vuetify.pagination.pageSizes.25' },
  ],
};
