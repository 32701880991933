<div id="app-root">
  <asking-dialog-box ref="askingDialogBox"></asking-dialog-box>
  <v-dialog persistent v-model="showDialog" max-width="1000px">
    <v-card>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <b>
              {{ $t('app.auto_logout',{minutes: actualMinutes, seconds:
              actualSeconds}) }}
            </b>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog
    persistent
    v-model="askForNativeNotificationPermission"
    max-width="700"
  >
    <v-card class="text-center text-body-1 py-2">
      {{ $t('app.webpush.overlay_text') }}
    </v-card>
  </v-dialog>
  <v-app v-if="!isKioskMode" full-height>
    <v-snackbar
      location="bottom right"
      :model-value="update.updateExists.value"
      :timeout="-1"
      color="primary"
    >
      {{$t('app.pwa_update.message')}}
      <v-btn variant="text" @click="update.refreshApp">
        {{$t('app.pwa_update.button_name')}}
      </v-btn>
    </v-snackbar>
    <v-app-bar height="60" :width="appbarWidth" elevation="1">
      <template v-slot:prepend>
        <v-app-bar-nav-icon v-if="isLoggedIn && !isTrialExpired" @click.stop="drawer = !drawer">
          <v-icon> {{ drawer ? 'mdi-close' : 'mdi-menu' }} </v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title v-if="!mobile || !showOwnUserLocationSelection">
          <router-link to="/">
            <v-img
              v-if="getterSettings.logoFile != ''"
              :src="getterSettings.logoFile"
              aspect-ratio="1.0"
              @click=""
              height="60"
              width="60"
              class="ml-2"
            ></v-img>
            <v-img
              v-else
              :src="require('@/assets/attmag-logo.png')"
              aspect-ratio="1.0"
              @click=""
              height="60"
              width="60"
              class="ml-2"
            ></v-img>
          </router-link>
        </v-toolbar-title>
      </template>

      <template v-if="!isTrialExpired" v-slot:default>
        <v-spacer></v-spacer>
        <own-user-location-department-select
          v-if="showOwnUserLocationSelection"
          :showDepartments="currentRouteName!==$ROUTES.public_view"
        ></own-user-location-department-select>
        <v-spacer></v-spacer>
      </template>

      <template v-slot:append>
        <div v-if="!isTrialExpired" :class="mobile ? 'd-flex' : 'd-flex mr-2'">
          <v-btn
            v-if="isLoggedIn && ( isAdmin || isEmployee || isTrainer )"
            icon
            @click="onClickScanner"
            class="mr-n3"
          >
            <d4y-img
              :src="require(`../../assets/checkIn_scanner.svg`)"
              height="30"
              width="30"
            ></d4y-img>
          </v-btn>

          <div v-if="!isEmailConfirmed && currentRouteName===$ROUTES.home">
            <v-menu
              location="bottom left"
              v-model="menuResendEmail"
              :max-width="400"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn size="small" v-bind="props" icon class="mt-1">
                  <v-icon color="red" size="xx-large">
                    mdi-alert-rhombus
                  </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-text class="text-body-1 pl-5">
                  <span> {{$t('app.confirmation.message1')}} </span>
                  <br /><br />
                  <span>{{$t('app.confirmation.message2')}} </span>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    variant="flat"
                    color="error"
                    @click="menuResendEmail = false"
                  >
                    {{$t('app.menu.popover.subscription_close')}}
                  </v-btn>
                  <v-btn variant="flat" color="primary" @click="resend()">
                    {{$t('app.confirmation.action')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div v-else-if="askForWebPushSubscription && currentRouteName===$ROUTES.home">
            <v-menu
              location="bottom left"
              v-model="askForWebPushSubscription"
              :max-width="400"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon>
                  <v-icon color="error" size="x-large"> mdi-bell-cog </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-text class="text-body-1 pl-5">
                  <div class="d-flex">
                    <div>{{$t('app.webpush.message1')}}</div>
                    <div>
                      <v-icon color="primary" size="x-large"> mdi-bell </v-icon>
                    </div>
                  </div>
                  <div class="mt-3">{{$t('app.webpush.message2')}}</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="flat"
                    color="error"
                    @click="webPushSubscriptionDeny()"
                  >
                    {{$t('app.webpush.deny')}}
                  </v-btn>
                  <v-btn
                    variant="flat"
                    color="primary"
                    @click="webPushSubscriptionGrant()"
                  >
                    {{$t('app.webpush.grant')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div v-else-if="showWebPushMessagesBell && currentRouteName===$ROUTES.home">
            <v-menu
              location="bottom right"
              :width="340"
              :max-height="600"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon @click="onClickPushMessagesBell">
                  <v-icon
                    :color="unreadWebpushMessages ? 'blue' : 'grey lighten-1'"
                    size="large"
                  >
                    mdi-bell
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  {{ $t('app.webpush.pushmessages') }}
                </v-card-title>
                <v-divider></v-divider>
                <v-list flat class="push-message-list-left-rim">
                  <template
                    v-for="(item, index) in webPushMessages.items"
                    :key="item.id"
                  >
                    <v-list-item>
                      <div class="text-caption">
                        {{
                        $filters.formatDateTimeWithLocaleAndTimezone(item.createdDate,
                        $i18n.locale) }}
                      </div>
                      <v-list-item-title
                        class="font-weight-medium mt-1 text-wrap"
                      >
                        <v-chip
                          v-if="!item.read"
                          color="blue"
                          text-color="white"
                          small
                          class="mr-1"
                        >
                          {{ $t('app.new') }}
                        </v-chip>
                        {{ item.title }}
                      </v-list-item-title>
                      <expandable-element
                        :baseHeight="40"
                        class="text-body-2 mt-2"
                      >
                        {{ item.text }}
                      </expandable-element>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                  <div class="d-flex justify-space-between align-center">
                    <v-btn
                      icon
                      flat
                      :disabled="webPushMessagesPage <= 1"
                      @click="decreaseWebPushMessagesPage"
                    >
                      <v-icon
                        size="large"
                        :color="webPushMessagesPage <= 1 ? 'grey' : 'blue'"
                      >
                        mdi-arrow-left-bold-circle-outline
                      </v-icon>
                    </v-btn>
                    <span class="text-body-1">
                      {{ $t('app.webpush.page', {page: webPushMessagesPage,
                      maxPage: webPushMessagesMaxPage}) }}
                    </span>
                    <v-btn
                      icon
                      flat
                      :disabled="webPushMessagesPage >= webPushMessagesMaxPage"
                      @click="increaseWebPushMessagesPage"
                    >
                      <v-icon
                        size="large"
                        :color="webPushMessagesPage >= webPushMessagesMaxPage ? 'grey' : 'blue'"
                      >
                        mdi-arrow-right-bold-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>

        <v-menu
          v-if="isLoggedIn"
          open-on-hover
          open-on-click
          :transition="false"
        >
          <template v-slot:activator="{ props }">
            <div v-bind="props" class="pointer-cursor d-flex align-center">
              <span
                v-if="!mobile"
                class="text-uppercase text-black text-body-1 mr-3"
              >
                {{ user.lastName + " " + user.firstName }}
              </span>
              <v-avatar class="pa-5 account-border-color">
                <img
                  v-if="user != '' && user.logo != ''"
                  :src="user.logo"
                  class="avatar-image-size"
                />
                <v-icon v-else color="black" size="36">mdi-account</v-icon>
              </v-avatar>
            </div>
          </template>
          <v-list dense min-width="220" class="menu-list">
            <v-list-item :to="`/${$ROUTES.my_profile}`" v-if="!isTrialExpired">
              <template v-slot:prepend>
                <v-icon>mdi-account-edit</v-icon>
              </template>
              <v-list-item-title>
                <span v-t="'app.menu.navdrawer.edit_profile'"></span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.my_membership}`" v-if="isAttendee">
              <template v-slot:prepend>
                <v-icon>mdi-text-box-check</v-icon>
              </template>
              <v-list-item-title>
                <span v-t="'app.menu.navdrawer.my_membership'"></span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.my_invoices}`" v-if="isAttendee">
              <template v-slot:prepend>
                <v-icon>mdi-account-card</v-icon>
              </template>
              <v-list-item-title>
                <span v-t="'app.menu.navdrawer.my_invoices'"></span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.my_documents}`" v-if="isAttendee">
              <template v-slot:prepend>
                <v-icon>mdi-file-document</v-icon>
              </template>
              <v-list-item-title>
                <span v-t="'app.menu.navdrawer.my_documents'"></span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="setLocale($i18n.locale =='en' ? 'de' : 'en')">
              <template v-slot:prepend>
                <d4y-img
                  size="20"
                  :src="$i18n.locale =='de' ? require(`../../assets/en-flag.svg`) : require(`../../assets/de-flag.svg`)"
                  round
                >
                </d4y-img>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.switchLanguage'"
                class="ml-9"
              ></v-list-item-title>
            </v-list-item>

            <v-list-item to="/notifications" v-if="!isTrialExpired">
              <template v-slot:prepend>
                <v-icon>mdi-bell</v-icon>
              </template>
              <v-list-item-title>
                <span v-t="'app.menu.navdrawer.notifications'"></span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.my_company}`" v-if="perms.myCompanyViewEdit">
              <template v-slot:prepend>
                <v-icon>mdi-office-building</v-icon>
              </template>
              <v-list-item-title>
                <span v-t="'app.menu.navdrawer.edit_company'"></span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.company_subscriptions}`" v-if="perms.companySubscriptionsViewEdit">
              <template v-slot:prepend>
                <v-icon>mdi-cash-multiple</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.company-subscriptions'"
              ></v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.statistics}`" v-if="perms.statisticsViewCreate">
              <template v-slot:prepend>
                <v-icon>mdi-chart-line</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.statistics'"
              ></v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.reference_tables}`" v-if="perms.referencesView">
              <template v-slot:prepend>
                <v-icon>mdi-tools</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.reference_tables'"
              ></v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/${$ROUTES.settings}`" v-if="perms.settingsViewEdit">
              <template v-slot:prepend>
                <v-icon>mdi-tools</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.settings'"
              ></v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item to="/helpVideos">
              <template v-slot:prepend>
                <v-icon>mdi-library</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.helpVideos'"
              ></v-list-item-title>
            </v-list-item>

            <v-list-item to="/innovations">
              <template v-slot:prepend>
                <v-icon>mdi-view-quilt</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.innovations'"
              ></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isLoggedIn" @click="sendFeedbackEmail()">
              <template v-slot:prepend>
                <v-icon>mdi-bullhorn</v-icon>
              </template>
              <v-list-item-title v-t="'app.menu.feedback'"></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAdmin" to="/audit">
              <template v-slot:prepend>
                <v-icon>mdi-format-line-style</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.audit'"
              ></v-list-item-title>
            </v-list-item>
            <v-divider v-show="isLoggedIn"></v-divider>

            <v-list-item @click="logout">
              <template v-slot:prepend>
                <v-icon class="action_logout">mdi-exit-to-app</v-icon>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.signout'"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          icon
          v-if="!isLoggedIn"
          class="mr-2"
          @click="setLocale($i18n.locale =='en' ? 'de' : 'en')"
        >
          <d4y-img
            :src="$i18n.locale =='de' ? require(`@/assets/en-flag.svg`) : require(`@/assets/de-flag.svg`)"
            size="40"
            round
          />
        </v-btn>

        <v-tooltip
          v-if="!isLoggedIn"
          :text="$t('app.menu.navdrawer.join')"
          location="bottom"
        >
          <template v-slot:activator="{ props }">
            <!-- Setting color of the button manually here. The appbar seems to keep overridding its appearance otherwise -->
            <v-btn
              style="background-color: #383838 !important"
              variant="elevated"
              @click="join"
              v-bind="props"
            >
              <v-icon size="large" color="white">mdi-account-plus</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="drawer"
      app
      disable-resize-watcher
      :floating="!mobile"
      :top="mobile"
      :absolute="mobile"
      :temporary="mobile"
      :permanent="drawer && !mobile"
      :mini-variant.sync="mini"
      class="mobile-drawer"
      color="primary"
      width="300"
    >
      <v-list nav class="app-list">
        <v-list-item to="/" base-color="white">
          <template v-slot:prepend>
            <d4y-img
              width="23"
              :src="require('@/assets/icons/home.svg')"
              class="mr-8"
            ></d4y-img>
          </template>
          <v-list-item-title class="text-body-1">
            <span v-t="'app.menu.navdrawer.home'"></span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="perms.locationsView"
          :to="`/${$ROUTES.locations}`"
          base-color="white"
        >
          <template v-slot:prepend>
            <d4y-img
              width="23"
              :src="require('@/assets/icons/location.svg')"
              class="mr-8"
            ></d4y-img>
          </template>
          <v-list-item-title
            v-t="'app.menu.navdrawer.locations'"
            class="text-body-1"
          ></v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="perms.employeesView"
          :to="`/${$ROUTES.employees}`"
          base-color="white"
        >
          <template v-slot:prepend>
            <d4y-img
              width="23"
              :src="require('../../assets/icons/employee.svg')"
              class="mr-8"
            ></d4y-img>
          </template>
          <v-list-item-title
            v-t="'app.menu.navdrawer.employees'"
            class="text-body-1"
          ></v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="perms.trainersView"
          :to="`/${$ROUTES.trainers}`"
          base-color="white"
        >
          <template v-slot:prepend>
            <d4y-img
              width="23"
              :src="require('@/assets/icons/trainer.svg')"
              class="mr-8"
            ></d4y-img>
          </template>
          <v-list-item-title
            v-t="'app.menu.navdrawer.trainers'"
            class="text-body-1"
          ></v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="perms.coursesView || perms.dateTimesView || perms.appointmentsView"
          base-color="white"
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" base-color="white">
              <template v-slot:prepend>
                <d4y-img
                  width="23"
                  :src="require('@/assets/icons/courses.svg')"
                  class="mr-8"
                ></d4y-img>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.courses_appointments'"
                class="text-body-1"
              ></v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            v-if="perms.coursesView"
            :to="`/${$ROUTES.courses}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="23"
                :src="require('@/assets/icons/courses.svg')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.courses'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="perms.dateTimesView"
            :to="`/${$ROUTES.courses_coursetimes}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="32"
                :src="require('@/assets/icons/course-times-white.png')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.course_times'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="perms.appointmentsView"
            :to="`/${$ROUTES.courses_appointments}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <v-img
                width="23"
                :src="require('../../assets/icons/appointments-white.png')"
                class="mr-8"
              ></v-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.appointments'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="perms.employeesView"
          base-color="white"
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" base-color="white">
              <template v-slot:prepend>
                <d4y-img
                  width="23"
                  :src="require('@/assets/icons/attendances.svg')"
                  class="mr-8"
                ></d4y-img>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.attendances'"
                class="text-body-1"
              ></v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            :to="`/${$ROUTES.attendances}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="23"
                :src="require('@/assets/icons/attendances.svg')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.attendances'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="`/${$ROUTES.attendances_presences}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="26"
                :src="require('@/assets/icons/presences-white.png')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.presence'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :to="`/${$ROUTES.attendances}`"
          base-color="white"
        >
          <template v-slot:prepend>
            <d4y-img
              width="23"
              :src="require('@/assets/icons/attendances.svg')"
              class="mr-8"
            ></d4y-img>
          </template>
          <v-list-item-title
            v-t="'app.menu.navdrawer.attendances'"
            class="text-body-1"
          ></v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="perms.invoicesView || perms.invoicePositionsView || perms.directDebitViewCreate"
          base-color="white"
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" base-color="white">
              <template v-slot:prepend>
                <d4y-img
                  width="23"
                  :src="require('@/assets/icons/invoices.png')"
                  class="mr-8"
                ></d4y-img>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.invoices'"
                class="text-body-1"
              ></v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            v-if="perms.invoicesView"
            :to="`/${$ROUTES.invoices}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="23"
                :src="require('@/assets/icons/invoices.png')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.invoices'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="perms.invoicePositionsView"
            :to="`/${$ROUTES.invoice_items}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="23"
                :src="require('@/assets/icons/single-costs-white.png')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.single_costs'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="perms.directDebitViewCreate"
            :to="`/${$ROUTES.direct_debit_creation}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <v-icon width="23">mdi-currency-eur</v-icon>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.direct_debit_creation'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="perms.attendeesView || perms.interestedView"
          base-color="white"
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" base-color="white">
              <template v-slot:prepend>
                <d4y-img
                  width="23"
                  :src="require('../../assets/icons/attendee.svg')"
                  class="mr-8"
                ></d4y-img>
              </template>
              <v-list-item-title
                v-t="'app.menu.navdrawer.attendee_interested'"
                class="text-body-1"
              ></v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            v-if="perms.attendeesView"
            :to="`/${$ROUTES.attendees}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <d4y-img
                width="35"
                :src="require('../../assets/icons/attendees.png')"
                class="mr-8"
              ></d4y-img>
            </template>
            <v-list-item-title
              v-t="isAdmin || isManager || isEmployee
                ? 'app.menu.navdrawer.attendees'
                : 'app.menu.navdrawer.my-attendees'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="perms.attendeesView"
            :to="`/${$ROUTES.attendee_presences}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <v-img
                width="26"
                :src="require('@/assets/icons/presences-white.png')"
                class="mr-8"
              ></v-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.attendee_presence'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="perms.interestedView"
            :to="`/${$ROUTES.attendees_interested}`"
            base-color="white"
          >
            <template v-slot:prepend>
              <v-img
                width="23"
                :src="require('@/assets/icons/attendees_interested.png')"
                class="mr-8"
              ></v-img>
            </template>
            <v-list-item-title
              v-t="'app.menu.navdrawer.interested'"
              class="text-body-1"
            ></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-if="perms.statisticsViewCreate"
          :to="`/${$ROUTES.statistics}`"
          base-color="white"
        >
        <template v-slot:prepend>
          <v-icon
            width="23"
            class="mr-8"
          >
            mdi-chart-line
          </v-icon>
          <v-list-item-title
            v-t="'app.menu.navdrawer.statistics'"
            class="text-body-1"
          ></v-list-item-title>
        </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main scrollable>
      <trial-expiry-information></trial-expiry-information>
      <router-view></router-view>
      <v-footer class="d-flex justify-space-between flex-wrap pt-4" app>
        <v-sheet class="display_inline_block bar-background-color text-white">
          <v-spacer></v-spacer>
          <v-icon>mdi-information</v-icon>

          <span>attmag.de</span>
          <span class="px-2" :title="$t('app.version_info',{version})">
            {{$t('app.version_info')}} {{packageVersion}}
          </span>
          <span
            class="px-1"
            :title="apiVersionInfo.version.informationalVersion"
          >
            {{$t('app.api_info')}}
            <span> {{apiVersionInfo.version.semVer}} </span>
            (
            <a
              :title="apiVersionInfo.commit.sha"
              :href="'https://github.com/dev4yougmbh/attmag-backend/commit/'+apiVersionInfo.commit.sha"
            >
              {{apiVersionInfo.commit.shortSha}}
            </a>
            )
          </span>
        </v-sheet>
        <div class="d-flex">
          <a
            target="_blank"
            :href="termsUrl"
            class="mr-2 hyperlinks-text-color"
          >
            {{ $t('app.footer_links.terms') }}
          </a>
          <a
            target="_blank"
            :href="imprintUrl"
            class="mr-2 hyperlinks-text-color"
          >
            {{ $t('app.footer_links.imprint') }}
          </a>
          <a :href="supportMailToUrl" class="mr-2 hyperlinks-text-color">
            {{ $t('app.footer_links.support') }}
          </a>
        </div>
      </v-footer>
    </v-main>
    <v-snackbar
      top
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.duration"
      right
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="releaseSnackbar()"> Ok </v-btn>
      </template>
    </v-snackbar>
  </v-app>
  <v-app v-else>
    <v-main scrollable>
      <router-view></router-view>
    </v-main>
  </v-app>
</div>
