import { CONST } from '../utils/constants';

export type MembershipRequestStatus = 0 | 1 | 2;
export const MembershipRequestStatus = {
  Open: 0 as MembershipRequestStatus,
  Done: 1 as MembershipRequestStatus,
  Rejected: 2 as MembershipRequestStatus,
};

export type MembershipRequestType = 0 | 1 | 2 | 3 | 4 | 5;
export const MembershipRequestType = {
  MembershipApplication: 0 as MembershipRequestType,
  SubscriptionOrder: 1 as MembershipRequestType,
  SubscriptionCancellation: 2 as MembershipRequestType,
  InvalidAgeForMemberStatus: 3 as MembershipRequestType,
  MembershipTermination: 4 as MembershipRequestType,
  ContactDataChanged: 5 as MembershipRequestType,
};

export interface MembershipRequest {
  id?: string; //System.Guid
  applicationUserId: string; //System.Guid
  membershipTypeId?: string; //System.Guid
  startDate?: string; //System.DateTime
  endDate?: string; //System.DateTime
  status: MembershipRequestStatus;
  type: MembershipRequestType;
  reason: string;
  wishes: string;
  additionalData: string;
  memberNumber: string;
  memberFullName: string;
  memberPhone: string;
  membershipTypeNames: string[];
  memberStatusName: string;
  isMemberDeleted: boolean;
  companyId?: string;
  createdDate?: string;
  editedByName: string;
  editedDate?: string; //System.DateTime
  readonly name: string; // for using the isNameAsRef prop
}

export interface Value {
  value: MembershipRequest[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<MembershipRequest>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    ApplicationUserId: data?.applicationUserId || CONST.emptyGuid,
    MembershipTypeId: data?.membershipTypeId || undefined,
    StartDate: data?.startDate || undefined,
    EndDate: data?.endDate || undefined,
    Status: data?.status || MembershipRequestStatus.Open,
    Type: data?.type || MembershipRequestType.MembershipApplication,
    Reason: data?.reason || undefined,
    Wishes: data?.wishes || undefined,
    AdditionalData: data?.additionalData || undefined,
    EditedDate: data?.editedDate || undefined,
    CompanyId: data?.companyId || CONST.emptyGuid,
    CreatedDate: data?.createdDate || new Date().toISOString(),
  };
}

function parse(data?: Partial<MembershipRequest>): MembershipRequest {
  let status = data?.status || MembershipRequestStatus.Open;
  let type = data?.type || MembershipRequestType.MembershipApplication;

  return {
    id: data?.id || undefined,
    applicationUserId: data?.applicationUserId || CONST.emptyGuid,
    membershipTypeId: data?.membershipTypeId || undefined,
    startDate: data?.startDate || '',
    endDate: data?.endDate || '',
    status:
      typeof status == 'string'
        ? MembershipRequestStatus[
            status as keyof typeof MembershipRequestStatus
          ]
        : (status as MembershipRequestStatus),
    type:
      typeof type == 'string'
        ? MembershipRequestType[type as keyof typeof MembershipRequestType]
        : (type as MembershipRequestType),
    reason: data?.reason || '',
    wishes: data?.wishes || '',
    additionalData: data?.additionalData || '',
    memberNumber: data?.memberNumber || '',
    memberFullName: data?.memberFullName || '',
    memberPhone: data?.memberPhone || '',
    membershipTypeNames: data?.membershipTypeNames || [],
    memberStatusName: data?.memberStatusName || '',
    isMemberDeleted: data?.isMemberDeleted ?? false,
    companyId: data?.companyId || undefined,
    createdDate: data?.createdDate || '',
    editedByName: data?.editedByName || '',
    editedDate: data?.editedDate || '',
    get name() {
      return this.memberFullName;
    },
  };
}

export function getMembershipRequestStatusText(
  status: MembershipRequestStatus
) {
  return Object.keys(MembershipRequestStatus)[
    Object.values(MembershipRequestStatus).findIndex((entry) => entry == status)
  ];
}

export default {
  parse,
  toAPI: toAPI,
};
